import React, { useCallback } from 'react'
import { graphql } from 'gatsby'
import qs from 'query-string'
import { PostList } from '../components/posts'
import { Pagination } from '../components/content'
import { LocationsFilter } from '../components/locations'
import { PageLayoutDefault } from '../components/layout'
import {
  MetaTagsMustHave, createPaginatedPageTitle, createPaginatedMetaProps,
} from '../components/seo'

const Category = ({
  data, location, pageContext, navigate,
}) => {
  const { category, posts } = data.wp
  const { locationsPage } = data.wp.themeOptions

  const associatedLocationCategorySlug = category.locationCategory?.slug

  const handleSubmitSearch = useCallback((newSearchValues) => {
    const searchValues = {
      ...newSearchValues,
      categorySlugs: associatedLocationCategorySlug,
    }

    navigate(`${locationsPage.uri}?${qs.stringify(searchValues)}`)
  }, [location.pathname])

  return (
    <>
      <MetaTagsMustHave
        {...createPaginatedMetaProps(pageContext.humanPageNumber, category.seo)}
      />
      <PageLayoutDefault
        heading={createPaginatedPageTitle(pageContext.humanPageNumber, category.name)}
      >
        {
          associatedLocationCategorySlug && (
            <LocationsFilter
              mb={3}
              displayCategory={false}
              onSubmit={handleSubmitSearch}
            />
          )
        }
        <PostList posts={posts.nodes} />
        <Pagination {...pageContext} />
      </PageLayoutDefault>
    </>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage(
    $first: Int!, $after: String, $categoryId: ID!, $uri: ID!
  ) {
    wp {
      category(
        id: $uri
        idType: URI
      ) {
        name
        locationCategory {
          slug
        }
        seo {
          title
          description: metaDesc
          datePublished: opengraphModifiedTime
          dateModified: opengraphPublishedTime
          opengraphImage {
            src: sourceUrl(size: LARGE)
          }
        }
      }
      posts(
        first: $first
        after: $after
        where: {
          status: PUBLISH
          language: DEFAULT
          categoryIn: [$categoryId]
          orderby: { field: DATE, order: DESC }
        }
      ) {
        nodes {
          ...PostListFields
          ...PostListImageFields
        }
      }

      themeOptions {
        locationsPage {
          uri
        }
      }
    }
  }
`
